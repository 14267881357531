<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      :filter-names="filtersName"
      no-filters
      @filterOption="onFiltersChange"
    >
      <template v-slot:actions="{ item }">
        <v-icon
          v-if="$admin.can('iot-device-models.edit')"
          class="mr-2"
          small
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="$admin.can('iot-device-models.delete')"
          class="mr-2"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>

      <!-- add Filters -->
      <template #addFilters>
        <!-- <v-card v-if="authUser.isAdmin">
          <v-select
            v-model="subFilters.iot_device_make_id"
            clearable
            dense
            :items="iotDeviceMakesList"
            item-value="id"
            item-text="name"
            :label="$t('manufacturer')"
          />
        </v-card> -->
      </template>
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <div>
          <create-iot-device-model
            v-if="$admin.can('iot-device-models.create')"
            :filter-options="options"
          />
          <edit-iot-device-model
            v-if="$admin.can('iot-device-models.edit') && editModal"
            :is-visible-dialog="editModal"
            :toggle-edit="toggleEditModal"
            :iot-device-model="selectedRow"
          />
          <confirmation-modal
            v-if="$admin.can('iot-device-models.delete')"
            :is-visible="deleteModal"
            :toggle="toggleDeleteModal"
            :row="selectedRow"
          />
        </div>
      </template>
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    CreateIotDeviceModel: () => import("./dialogs/CreateIotDeviceModel.vue"),
    EditIotDeviceModel: () => import("./dialogs/EditIotDeviceModel.vue"),
    ConfirmationModal: () =>
      import("@/components/Modals/ConfirmationModal.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      filters: {},
      subFilters: {},

      headers: [
        {
          text: this.$t("id"),
          align: "left",
          sortable: true,
          value: "id",
        },
        {
          text: this.$t("name"),
          align: "left",
          sortable: false,
          value: "name",
          // width: "15%",
        },
        {
          text: this.$t("manufacturer"),
          align: "left",
          sortable: false,
          value: "manufacturer_name",
          // width: "10%",
        },
        {
          text: this.$t("flespi_device_type_id"),
          align: "left",
          sortable: false,
          value: "flespi_device_type_id",
          // width: "10%",
        },

        ...(this.$admin.hasAccessTo("iot-device-models.delete") ||
          this.$admin.hasAccessTo("iot-device-models.edit")
          ? [
            {
              text: this.$t("actions"),
              align: "left",
              sortable: false,
              value: "actions",
            },
          ]
          : []),
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "iotDeviceModels/getIsLoadingIotDeviceModels",
      meta: "iotDeviceModels/getIotDeviceModelsMeta",
      list: "iotDeviceModels/getIotDeviceModelsList",

      iotDeviceMakesList: "iotDeviceMakes/iotDeviceMakesList",
    }),
    _headers() {
      // computed headers for showing columns to role based
      return this.headers.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },
    filtersName() {
      if (this.authUser.isAdmin) {
        return ["iot_device_make"];
      } else {
        return [];
      }
    },
  },
  watch: {
    options() {
      this.fetchData();
    },
  },
  async mounted() {
    await this.$store.dispatch("iotDeviceMakes/list");
  },
  beforeDestroy() {
    const params = {
      name: "iot_device_providers",
      filters: {
        page: this.options.page,
        itemsPerPage: this.options.itemsPerPage,
        iot_device_make_id: this.options.iot_device_make_id || undefined,
      },
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    async fetchData() {
      await this.$store
        .dispatch("iotDeviceModels/list", this.options)
        .then(() => {
          if (this.options.page > (this.meta?.lastPage || 1)) {
            this.options.page = 1;
          }
        });
    },

    // Confirmation of Delete Row
    async deleteSelectedRow(row) {
      try {
        await this.$store.dispatch("iotDeviceModels/destroy", row);
        this.$store.dispatch("alerts/success", this.$t("deleted_successfully"));
        this.toggleDeleteModal("close");
        this.fetchData();
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
  },
};
</script>